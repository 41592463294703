import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Orders               from "Utils/Orders";

// Components
import ProcessProducts      from "Components/App/Dashboard/Process/Components/ProcessProducts";



// Styles
const Content = Styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    padding: 16px;
    margin-right: var(--main-margin);
    margin-bottom: var(--main-margin);
    border-radius: var(--main-radius);
    background-color: var(--content-color);
`;



/**
 * The Order Manage Products
 * @returns {React.ReactElement}
 */
function OrderManageProducts() {
    const { data, errors, unitPrices, pamiPrices, prices, subTotals } = Store.useState("orderState");

    // Hooks
    const handleChange = Orders.useHandleChange();


    // Do the Render
    return <Content>
        <ProcessProducts
            data={data}
            errors={errors}
            unitPrices={{ current : unitPrices }}
            pamiPrices={{ current : pamiPrices }}
            prices={{ current : prices }}
            subTotals={{ current : subTotals }}
            onChange={handleChange}
            // onChange={handleOnChange}
        />
    </Content>;
}

export default OrderManageProducts;
