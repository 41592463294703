import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import ScheduleFilter       from "./ScheduleFilter";
import ScheduleEdit         from "./ScheduleEdit";
import ScheduleMultiEdit    from "./ScheduleMultiEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Schedule List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ScheduleList(props) {
    const { type } = props;
    const { fetch, load, loadTab, loadFilter } = useList("subsidiarySchedule", type);

    const { pharmacyID, subsidiaryID } = Navigate.useParams();

    const data = Store.useState("subsidiarySchedule");
    const { loading, canEdit, list, total, filters, sort } = data;

    const { deleteElem } = Store.useAction("subsidiarySchedule");

    // The Current State
    const [ checked, setChecked ] = React.useState([]);
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Action
    const handleAction = async (action, elemID) => {
        if (action.isTab) {
            loadTab(elemID);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        setChecked([]);
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteElem(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Variables
    const canMulti       = canEdit && checked.length > 0;
    const elemName       = Utils.getValue(list, "subsidiaryScheduleID", elemID, "scheduleTypeText");
    const showSubsidiary = type === "ADMIN" || type === "PHARMACY";
    const scheduleTypes  = NLS.select("SELECT_SCHEDULE_TYPES");


    // Do the Render
    return <Main>
        <Header message="SCHEDULES_NAME" icon="schedule">
            <ActionList data={data} onAction={handleAction}>
                <ActionItem
                    isHidden={!canMulti}
                    action="MULTI"
                />
            </ActionList>
        </Header>
        <Content>
            <TabList
                isHidden={!showSubsidiary}
                selected={sort.filter}
                onAction={handleAction}
            >
                <TabItem message="GENERAL_ALL" value="all" />
                {scheduleTypes.map((elem) => <TabItem
                    key={elem.key}
                    message={elem.value}
                    value={elem.key}
                />)}
            </TabList>

            <Table
                fetch={load}
                sort={sort}
                none="SCHEDULES_NONE_AVAILABLE"
                isLoading={loading}
                checked={checked}
                setChecked={setChecked}
                hasCheckAll
            >
                <TableHead>
                    <TableHeader field="subsidiaryFullName" message="SUBSIDIARIES_SINGULAR" isTitle grow="2" isHidden={!showSubsidiary} />
                    <TableHeader field="scheduleType"       message="GENERAL_TYPE"          isTitle={!showSubsidiary} maxWidth="120" />
                    <TableHeader field="timeTables"         message="SCHEDULES_SINGULAR"    grow="2" noSorting />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.subsidiaryScheduleID} elemID={elem.subsidiaryScheduleID}>
                        <TableCell message={elem.subsidiaryFullName} />
                        <TableCell message={elem.scheduleTypeText}   />
                        <TableCell message={elem.timeTableText}      />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="SCHEDULES_EDIT_TITLE"   />
                    <TableAction action="COPY"   message="SCHEDULES_COPY_TITLE"   />
                    <TableAction action="DELETE" message="SCHEDULES_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <ScheduleFilter
            open={action.isFilter}
            filters={filters}
            pharmacyID={pharmacyID}
            onSubmit={handleFilter}
            onClose={endAction}
        />
        <ScheduleEdit
            open={action.isVCCE}
            isCopy={action.isCopy}
            elemID={elemID}
            pharmacyID={pharmacyID}
            subsidiaryID={subsidiaryID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <ScheduleMultiEdit
            open={action.isMulti}
            elemIDs={checked}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="SCHEDULES_DELETE_TITLE"
            message="SCHEDULES_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ScheduleList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default ScheduleList;
