import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Schedule Multi Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ScheduleMultiEdit(props) {
    const { open, elemIDs, onClose, onSubmit } = props;

    const { editMultiElem } = Store.useAction("subsidiarySchedule");


    // The Initial Data
    const initialData = {
        timeTables : "[{}]",
    };

    // Handles the Set
    const handleSet = (elem) => {
        setElem({
            ...elem,
            timeTables : elem?.timeTables?.length ? JSON.stringify(elem.timeTables) : "[{}]",
        });
    };

    // Handles the Edit
    const handleEdit = (data) => {
        return editMultiElem({
            ...data,
            subsidiaryScheduleIDs : JSON.stringify(elemIDs),
        });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("subsidiarySchedule", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("subsidiarySchedule", open, elemIDs[0], null, handleSet);


    // Do the Render
    return <EditDialog
        open={open}
        icon="schedule"
        title="SCHEDULES_MULTI_EDIT_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            type="fields"
            name="timeTables"
            title="GENERAL_PERIOD_TITLE"
            addButton="GENERAL_ADD_PERIOD"
            value={data.timeTables}
            errors={errors}
            onChange={handleChange}
            columns="2"
            isRequired
        >
            <InputItem
                type="chooser"
                name="days"
                label="GENERAL_DAYS"
                options="DATE_DAY_NAMES"
                columns="2"
                isRequired
            />
            <InputItem
                type="time"
                name="from"
                label="GENERAL_FROM"
                isRequired
            />
            <InputItem
                type="time"
                name="to"
                label="GENERAL_TO"
                isRequired
            />
        </InputField>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ScheduleMultiEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemIDs  : PropTypes.arrayOf(PropTypes.number),
};

export default ScheduleMultiEdit;
