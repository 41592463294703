import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import OrderSection         from "../Utils/OrderSection";
import OrderText            from "../Utils/OrderText";



/**
 * The Order Manage Retirement
 * @returns {React.ReactElement}
 */
function OrderManageRetirement() {
    const { elem } = Store.useState("orderManage");


    // Variables
    const retirementIcon = elem.retirementCode?.toLowerCase() ?? "retirement";


    // Do the Render
    return <OrderSection
        icon={retirementIcon}
        message={elem.retirementTypeName}
    >
        <OrderText
            title="GENERAL_LOCATION"
            message={elem.clientLocation}
        />
        <OrderText
            title="GENERAL_ADDRESS_ADDITIONAL"
            message={elem.clientAddressAdditional}
        />
        <OrderText
            isHidden={!elem.logisticExternalID}
            title="ORDERS_LOGISTIC_EXTERNAL_ID"
            message={elem.logisticExternalID}
        />
    </OrderSection>;
}

export default OrderManageRetirement;
