import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import OrderSection         from "../Utils/OrderSection";



/**
 * The Order Manage Gateway
 * @returns {React.ReactElement}
 */
function OrderManageGateway() {
    const { elem } = Store.useState("orderManage");


    // Do the Render
    if (!elem.gatewayName) {
        return <React.Fragment />;
    }
    return <OrderSection
        icon={elem.gatewayIcon || "gateway"}
        message={elem.gatewayName}
    />;
}

export default OrderManageGateway;
