import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";



// Styles
const Container = Styled.section`
    --main-gap: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
`;

const Title = Styled.h3`
    margin: 0;
    color: var(--alternative-color);
    font-family: var(--title-font);
    font-weight: var(--title-font-weight);
    font-size: 18px;
`;



/**
 * The View Section
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ViewSection(props) {
    const { className, message, children } = props;


    // Do the Render
    return <Container className={className}>
        {message && <Title>{NLS.get(message)}</Title>}
        {children}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ViewSection.propTypes = {
    className : PropTypes.string,
    message   : PropTypes.string,
    children  : PropTypes.any,
};

export default ViewSection;
