import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Orders               from "Utils/Orders";

// Components
import OrderManageClient    from "./OrderManageClient";
import OrderManageGateway   from "./OrderManageGateway";
import OrderManageRetire    from "./OrderManageRetirement";
import OrderManageCoverage  from "./OrderManagerCoverage";
import ProcessRequest       from "Components/App/Dashboard/Process/Components/ProcessRequest";
import ProcessStates        from "Components/App/Dashboard/Process/Components/ProcessStates";



// Styles
const Content = Styled.div`
    flex-grow: 2;
    display: flex;
    flex-direction: row;
    gap: var(--main-gap);
    padding: 16px;
    padding-top: 0;

    > * {
        width: calc(100% / 4);
    }
    :last-child {
        max-height: 350px;
        overflow: auto;
    };
`;

const Column = Styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;



/**
 * The Order Manage Details
 * @returns {React.ReactElement}
 */
function OrderManageDetails() {
    const { data, errors } = Store.useState("orderState");

    // Hooks
    const handleChange = Orders.useHandleChange();


    // Do the Render
    return <Content>
        <Column>
            <OrderManageClient />
        </Column>
        <Column>
            <ProcessRequest
                data={data}
                errors={errors}
                onChange={handleChange}
                // onChange={handleOnChange}
            />
        </Column>
        <Column>
            <OrderManageGateway />
            <OrderManageRetire />
            <OrderManageCoverage />
        </Column>
        <Column>
            <ProcessStates />
        </Column>
    </Content>;
}

export default OrderManageDetails;
