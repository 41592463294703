import Store                from "Dashboard/Core/Store";
import Orders               from "Utils/Orders";



// The Initial Data
const initialData = {
    orderID                  : 0,
    coverageTypeID           : 0,
    coverageID               : 0,
    clientCoverageName       : "",
    clientCoveragePlan       : "",
    clientCoverageDni        : "",
    clientCoverageCredential : "",
    observations             : "",
    products                 : "[{}]",
    ticketNumber             : "",
    invoiceNumber            : "",
};

const initialErrors = {
    clientCoverageName       : "",
    clientCoveragePlan       : "",
    clientCoverageDni        : "",
    clientCoverageCredential : "",
    observations             : "",
    products                 : "",
    ticketNumber             : "",
    invoiceNumber            : "",
};

// The initial State
const initialState = {
    data            : { ...initialData },
    errors          : { ...initialErrors },

    prices          : [],
    unitPrices      : [],
    pamiPrices      : [],
    subTotals       : [],

    dialog          : "",
    reqCoverageFile : false,
    coverageFile    : "",
};



// The Actions
const actions = {
    /**
     * Sets the Initial Data
     * @param {Function} dispatch
     * @param {Object}   order
     * @param {Object[]} products
     * @param {Number[]} unitPrices
     * @param {Number[]} prices
     * @param {Number[]} subTotals
     * @returns {Void}
     */
    setInitialData(dispatch, order, products, unitPrices, prices, subTotals) {
        dispatch({ type : "ORDER_STATE_INITIAL_DATA", order, products, unitPrices, prices, subTotals });
    },

    /**
     * Sets the Data
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Void}
     */
    setData(dispatch, data) {
        dispatch({ type : "ORDER_STATE_DATA", data });
    },

    /**
     * Sets the Errors
     * @param {Function} dispatch
     * @param {Object}   errors
     * @returns {Void}
     */
    setErrors(dispatch, errors) {
        dispatch({ type : "ORDER_STATE_ERRORS", errors });
    },

    /**
     * Resets the Errors
     * @param {Function} dispatch
     * @returns {Void}
     */
    resetErrors(dispatch) {
        dispatch({ type : "ORDER_STATE_RESET_ERRORS" });
    },

    /**
     * Sets the Dialog
     * @param {Function} dispatch
     * @param {String}   dialog
     * @returns {Void}
     */
    setDialog(dispatch, dialog) {
        dispatch({ type : "ORDER_STATE_DIALOG", dialog });
    },

    /**
     * Sets the Req Coverage File
     * @param {Function} dispatch
     * @param {String}   reqCoverageFile
     * @returns {Void}
     */
    setReqCoverageFile(dispatch, reqCoverageFile) {
        dispatch({ type : "ORDER_STATE_REQ_COVERAGE_FILE", reqCoverageFile });
    },

    /**
     * Sets the Coverage File
     * @param {Function} dispatch
     * @param {String}   coverageFile
     * @returns {Void}
     */
    setCoverageFile(dispatch, coverageFile) {
        dispatch({ type : "ORDER_STATE_COVERAGE_FILE", coverageFile });
    },
};




/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
    case "ORDER_STATE_INITIAL_DATA":
        return {
            ...state,
            data            : {
                orderID                  : action.order.orderID,
                coverageTypeID           : action.order.coverageTypeID,
                coverageID               : action.order.coverageID,
                clientCoverageName       : action.order.clientCoverageName,
                clientCoveragePlan       : action.order.clientCoveragePlan,
                clientCoverageDni        : action.order.clientCoverageDni,
                clientCoverageCredential : action.order.clientCoverageCredential,
                discountType             : action.order.discountType,
                discountValue            : action.order.discountValue,
                ticketNumber             : action.order.ticketNumber,
                invoiceNumber            : action.order.invoiceNumber,
                observations             : action.order.observations,
                products                 : Orders.encodeProducts(action.products),
            },
            errors          : { ...initialErrors },
            prices          : action.prices,
            unitPrices      : action.unitPrices,
            subTotals       : action.subTotals,
            dialog          : "",
            coverageFile    : "",
            reqCoverageFile : action.order.reqCoverage && !action.order.clientCoverageID,
        };

    case "ORDER_STATE_DATA":
        return {
            ...state,
            data            : { ...state.data, ...action.data },
        };

    case "ORDER_STATE_ERRORS":
        return {
            ...state,
            errors          : { ...state.errors, ...action.errors },
        };

    case "ORDER_STATE_RESET_ERRORS":
        return {
            ...state,
            errors          : { ...initialErrors },
        };


    case "ORDER_STATE_DIALOG":
        return {
            ...state,
            dialog          : action.dialog,
        };

    case "ORDER_STATE_REQ_COVERAGE_FILE":
        return {
            ...state,
            reqCoverageFile : action.reqCoverageFile,
        };

    case "ORDER_STATE_COVERAGE_FILE":
        return {
            ...state,
            coverageFile    : action.coverageFile,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
