import React                from "react";
import Action               from "Dashboard/Core/Action";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import { Product }          from "Utils/API";

// Components
import ProductFilter        from "./Dialogs/ProductFilter";
import ProductEdit          from "./Dialogs/ProductEdit";
import ProductMultiEdit     from "./Dialogs/ProductMultiEdit";
import ProductImport        from "./Dialogs/ProductImport";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionOption         from "Dashboard/Components/Header/ActionOption";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";
import Image                from "Dashboard/Components/Common/Image";



/**
 * The Product List
 * @returns {React.ReactElement}
 */
function ProductList() {
    const { fetch, load } = useList("product");

    const {
        loading, canEdit, canFilter, canImport, canExport,
        list, total, filters, hasFilters, sort,
    } = Store.useState("product");

    const { saveFilters, deleteElem } = Store.useAction("product");

    // The Current State
    const [ checked,   setChecked   ] = React.useState([]);
    const [ exporting, setExporting ] = React.useState(false);
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Action
    const handleAction = async (action, elemID) => {
        if (action.isExport) {
            setExporting(true);
            endAction();
            const source = Product.export();
            await Utils.download(source, NLS.get("PRODUCTS_FILE_NAME"));
            setExporting(false);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Filter Submit
    const handleFilter = async (filters) => {
        endAction();
        await saveFilters(filters);
        load();
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        setChecked([]);
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteElem(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Variables
    const canMulti  = canEdit && checked.length > 0;
    const hasExport = canExport && total > 0;
    const elemName  = Utils.getValue(list, "productID", elemID, "name");


    // Do the Render
    return <Main>
        <Header message="PRODUCTS_NAME" icon="product">
            <ActionList
                onAction={handleAction}
                withImport={false}
                withExport={false}
            >
                <ActionItem
                    isHidden={!canFilter}
                    action="FILTER"
                    withMark={hasFilters}
                />
                <ActionItem
                    isHidden={!canMulti}
                    action="MULTI"
                />
                <ActionItem
                    isHidden={!canEdit}
                    icon="more"
                    message="GENERAL_ACTIONS"
                    isLoading={exporting}
                >
                    <ActionOption
                        isHidden={!canEdit}
                        action="CREATE"
                        message="PRODUCTS_CREATE_TITLE"
                    />
                    <ActionOption
                        isHidden={!canImport}
                        action="IMPORT"
                        message="PRODUCTS_IMPORT_TITLE"
                    />
                    <ActionOption
                        isHidden={!hasExport}
                        action="EXPORT"
                        message="PRODUCTS_EXPORT_TITLE"
                    />
                </ActionItem>
            </ActionList>
        </Header>

        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="PRODUCTS_NONE_AVAILABLE"
                isLoading={loading}
                checked={checked}
                setChecked={setChecked}
                hasCheckAll
            >
                <TableHead>
                    <TableHeader field="image"             message=""                          noSorting noSpace maxWidth="40" />
                    <TableHeader field="name"              message="GENERAL_NAME"              isTitle grow="2" />
                    <TableHeader field="benvidaID"         message="GENERAL_CODE_BENVIDA"      maxWidth="120" />
                    <TableHeader field="alfabetaID"        message="GENERAL_CODE_ALFABETA"     maxWidth="120" />
                    <TableHeader field="macroCategoryName" message="MACRO_CATEGORIES_SINGULAR" maxWidth="140" />
                    <TableHeader field="categoryName"      message="CATEGORIES_SINGULAR"       />
                    <TableHeader field="subCategoryName"   message="SUB_CATEGORIES_SINGULAR"   />
                    <TableHeader field="updatedTime"       message="GENERAL_MODIFIED_AT"       minWidth="160" maxWidth="160" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.productID} elemID={elem.productID}>
                        <TableCell>
                            <Image
                                source={elem.image}
                                message={elem.name}
                                size="40"
                            />
                        </TableCell>
                        <TableCell message={elem.name}              />
                        <TableCell message={elem.benvidaID}         />
                        <TableCell message={elem.alfabetaID}        />
                        <TableCell message={elem.macroCategoryName} />
                        <TableCell message={elem.categoryName}      />
                        <TableCell message={elem.subCategoryName}   />
                        <TableCell message={elem.updatedTimeDate}   />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW"   message="PRODUCTS_VIEW_TITLE"   navigate />
                    <TableAction action="EDIT"   message="PRODUCTS_EDIT_TITLE"   />
                    <TableAction action="DELETE" message="PRODUCTS_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <ProductFilter
            open={action.isFilter}
            filters={filters}
            onSubmit={handleFilter}
            onClose={endAction}
        />
        <ProductEdit
            open={action.isVCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <ProductMultiEdit
            open={action.isMulti}
            elemIDs={checked}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <ProductImport
            open={action.isImport}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="PRODUCTS_DELETE_TITLE"
            message="PRODUCTS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

export default ProductList;
