import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import OrderSection         from "../Utils/OrderSection";
import OrderText            from "../Utils/OrderText";
import Button from "Dashboard/Components/Form/Button";



/**
 * The Order Manage Coverage
 * @returns {React.ReactElement}
 */
function OrderManageCoverage() {
    const { elem } = Store.useState("orderManage");


    // Do the Render
    return <OrderSection
        icon="coverage"
        message={elem.coverageTypeName}
    >
        <OrderText
            title="GENERAL_TYPE"
            message={elem.clientCoverageName}
        />
        <OrderText
            title="GENERAL_NAME"
            message={elem.clientCoverageName}
        />
        <OrderText
            title="ORDERS_CREDENTIAL_PLAN"
            message={elem.clientCoveragePlan}
        />
        <OrderText
            isHidden={!elem.clientCoverageDni}
            title="ORDERS_CREDENTIAL_DNI"
            message={elem.clientCoverageDni}
        />
        <OrderText
            title="ORDERS_CREDENTIAL_NUMBER"
            message={elem.clientCoverageCredential}
        />

        <Button
            variant="outlined"
            icon="view"
            message="ORDERS_CREDENTIAL_VIEW"
            // onClick={() => setRecipeIndex(index + 1)}
            fullWidth
            isSmall
        />
    </OrderSection>;
}

export default OrderManageCoverage;
