import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";
import { Contact }          from "Utils/API";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Contact Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ContactFilter(props) {
    const { open, filters, pharmacyID, forSubsidiary, onClose, onSubmit } = props;

    const { pharmacies, subsidiaries, subsidiariesPerPharmacy } = Store.useState("contact");


    // The Initial Data
    const initialData = {
        contactID     : 0,
        name          : "",
        exclude       : 0,
        pharmacyIDs   : [],
        subsidiaryIDs : [],
    };

    // The Filter State
    const {
        loading, data, handleChange, handleSubmit,
    } = useFilter("contact", open, initialData, filters, onSubmit);

    // Returns a select of subsidiaries based on the pharmacies
    const pharmacyIDs     = pharmacyID ? [ pharmacyID ] : data.pharmacyIDs;
    const getSubsidiaries = Utils.useSelectList(loading, pharmacyIDs, subsidiaries, subsidiariesPerPharmacy);


    // Do the Render
    const showPharmacy = !forSubsidiary && !pharmacyID;

    return <FilterDialog
        open={open}
        title="CONTACTS_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            type="suggest"
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            suggestID="contactID"
            suggestFetch={Contact.search}
            onChange={handleChange}
        />

        <InputField
            isHidden={forSubsidiary}
            type="toggle"
            name="exclude"
            label="GENERAL_EXCLUDE_FILTER"
            value={data.exclude}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!showPharmacy}
            type="chooser"
            name="pharmacyIDs"
            label="PHARMACIES_NAME"
            options={pharmacies}
            value={data.pharmacyIDs}
            onChange={handleChange}
        />
        <InputField
            isHidden={forSubsidiary}
            type="chooser"
            name="subsidiaryIDs"
            label="SUBSIDIARIES_NAME"
            options={getSubsidiaries()}
            value={data.subsidiaryIDs}
            onChange={handleChange}
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ContactFilter.propTypes = {
    open          : PropTypes.bool.isRequired,
    onClose       : PropTypes.func.isRequired,
    onSubmit      : PropTypes.func.isRequired,
    filters       : PropTypes.object,
    pharmacyID    : PropTypes.number,
    forSubsidiary : PropTypes.bool,
};

export default ContactFilter;
