import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import DashboardProcessPage from "Components/App/Dashboard/DashboardProcessPage";
import OrderListUser        from "Components/App/Order/OrderListUser";
import OrderPage            from "Components/App/Order/OrderPage";
import GatewayPageUser      from "Components/App/Business/Gateway/GatewayPageUser";
import ProfileContainer     from "Components/App/Profile/ProfileContainer";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import UserRoute            from "Dashboard/Components/Route/UserRoute";



/**
 * The Operative Router
 * @returns {React.ReactElement}
 */
function OperativeRouter() {
    const { isOperative, subsidiaryID } = Store.useState("auth");


    // Do the Render
    if (!isOperative) {
        return <React.Fragment />;
    }
    return <Router type="USER">
        <UserRoute
            url="DASHBOARD"
            component={DashboardProcessPage}
        />
        <UserRoute
            url="DASHBOARD_ORDER"
            component={DashboardProcessPage}
        />

        <UserRoute
            url="ORDERS"
            component={OrderListUser}
        />
        <UserRoute
            url="ORDERS_TAB"
            component={OrderListUser}
        />
        <UserRoute
            url="ORDER"
            component={OrderPage}
        />

        <UserRoute
            isHidden={!subsidiaryID}
            url="GATEWAYS"
            component={GatewayPageUser}
        />

        <UserRoute
            url="PROFILE"
            component={ProfileContainer}
        />
    </Router>;
}

export default OperativeRouter;
