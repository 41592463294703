import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import AdminEdit            from "./AdminEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Admin List
 * @returns {React.ReactElement}
 */
function AdminList() {
    const { fetch, load } = useList("admin");

    const data = Store.useState("admin");
    const { loading, canEdit, list, total, sort } = data;

    const { loginAs } = Store.useAction("auth");
    const { deleteElem } = Store.useAction("admin");


    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Action
    const handleAction = (action, elemID) => {
        if (action.isLogin) {
            loginAs(elemID);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteElem(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const elemName = Utils.getValue(list, "credentialID", elemID, "fullName");

    return <Main>
        <Header message="ADMINS_NAME" icon="admin">
            <ActionList data={data} onAction={handleAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="ADMINS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="access"    message="GENERAL_ACCESS"     maxWidth="120" isTitle />
                    <TableHeader field="fullName"  message="GENERAL_FIRST_NAME" />
                    <TableHeader field="email"     message="GENERAL_EMAIL"      grow="2" />
                    <TableHeader field="lastLogin" message="GENERAL_LOGIN_TIME" maxWidth="120" hideMobile />
                    <TableHeader field="status"    message="GENERAL_STATUS"     maxWidth="70" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.credentialID} elemID={elem.credentialID}>
                        <TableCell message={elem.accessName} />
                        <TableCell message={elem.fullName}   />
                        <TableCell message={elem.email}      />
                        <TableCell message={elem.loginDate}  />
                        <TableCell message={elem.statusName} textColor={elem.statusColor} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="ADMINS_EDIT_TITLE"   />
                    <TableAction action="LOGIN"  message="GENERAL_LOGIN_AS"    />
                    <TableAction action="DELETE" message="ADMINS_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <AdminEdit
            open={action.isVCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="ADMINS_DELETE_TITLE"
            message="ADMINS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

export default AdminList;
