import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";

// Components
import ChatMedia            from "Components/App/Order/Chat/ChatMedia";
import ChatEdit             from "Components/App/Order/Chat/ChatEdit";

// Dashboard
import Html                 from "Dashboard/Components/Common/Html";



// Styles
const Container = Styled.div.attrs(({ isMine, hasEdit }) => ({ isMine, hasEdit }))`
    --bubble-padding: ${(props) => props.hasEdit ? (24 + 8 + 12) : 12}px;

    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: min(calc(680px - var(--bubble-padding)), calc(100% - var(--bubble-padding)));
    margin-bottom: 4px;

    ${(props) => props.isMine ? `
        --bubble-background: var(--bubble-mine-bg, #1f85ff);
        --bubble-color: var(--bubble-mine-color, white);

        flex-direction: row-reverse;
        padding-left: var(--bubble-padding);

        &:last-child .bubble::before {
            content: "";
            position: absolute;
            z-index: 0;
            bottom: 0;
            right: -8px;
            height: 20px;
            width: 20px;
            background: var(--bubble-background);
            border-bottom-left-radius: 15px;
        }
        &:last-child .bubble::after {
            content: "";
            position: absolute;
            z-index: 1;
            bottom: 0;
            right: -10px;
            width: 10px;
            height: 21px;
            background: white;
            border-bottom-left-radius: 10px;
        }
    ` : `
        --bubble-background: var(--bubble-your-bg, #e2e3e4);
        --bubble-color: var(--bubble-your-color, var(--black-color));

        flex-direction: row;
        padding-right: var(--bubble-padding);

        &:last-child .bubble::before {
            content: "";
            position: absolute;
            z-index: 0;
            bottom: 0;
            left: -7px;
            height: 20px;
            width: 20px;
            background: var(--bubble-background);
            border-bottom-right-radius: 15px;
        }
        &:last-child .bubble::after {
            content: "";
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: -10px;
            width: 10px;
            height: 20px;
            background: white;
            border-bottom-right-radius: 10px;
        }
    `}

    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        --bubble-padding: 12px;
    }
    &:hover .bubble-action {
        display: block;
    }
`;

const Content = Styled.div.attrs(({ isMine }) => ({ isMine }))`
    overflow: hidden;
    ${(props) => props.isMine ? "padding-right: 12px" : "padding-left: 12px"};
`;

const Inner = Styled.div.attrs(({ withSpacing }) => ({ withSpacing }))`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 12px;
    border-radius: 16px;
    min-width: ${(props) => props.withSpacing ? "80px" : "40px"};;
    min-height: 17px;
    background-color: var(--bubble-background);
    color: var(--bubble-color);
`;

const Text = Styled(Html).attrs(({ hasIcon, withSpacing, useBigFont }) => ({ hasIcon, withSpacing, useBigFont }))`
    --text-space: ${(props) => props.hasIcon ? "48px" : "32px"};
    ${(props) => props.withSpacing && "margin-right: var(--text-space)"};
    ${(props) => props.useBigFont && "font-size: 32px;"};

    overflow: hidden;
    text-overflow: ellipsis;

    a {
        color: var(--bubble-color);
    }
`;

const Footer = Styled.footer.attrs(({ isMine, withSpacing }) => ({ isMine, withSpacing }))`
    font-size: 11px;
    color: ${(props) => props.isMine ? "rgba(255, 255, 255, 0.8)" : "rgba(0, 0, 0, 0.5)"};

    ${(props) => props.withSpacing ? `
        position: absolute;
        bottom: 4px;
        right: 8px;
    ` : `
        text-align: right;
        margin-top: -10px;
        transform: translate(2px, 4px);
    `}
`;



/**
 * The Chat Bubble
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ChatBubble(props) {
    const { item, isMine, canEdit, recipeCount, reqCoverage, hasCoverage, onAction } = props;


    // Variables
    const hasText     = Boolean(item.message);
    const hasEdit     = Boolean(canEdit && item.file);
    const useBigFont  = Boolean(!item.file && hasText && Utils.isEmojiOnly(item.message) && item.message.length <= 8);
    const withSpacing = Boolean(!useBigFont && !item.replyToID && !item.file && item.message.length < 35);


    // Do the Render
    return <Container isMine={isMine} hasEdit={hasEdit}>
        <Content className="bubble-content" isMine={isMine}>
            <Inner className="bubble" withSpacing={withSpacing}>
                <ChatMedia media={item} />
                {hasText && <Text
                    hasIcon={isMine}
                    withSpacing={withSpacing}
                    useBigFont={useBigFont}
                    content={item.message}
                    addLinks
                    addBreaks
                    formatText
                />}

                <Footer isMine={isMine} withSpacing={withSpacing}>
                    <span>{DateTime.formatDate(item.createdTime, "time")}</span>
                </Footer>
            </Inner>
        </Content>

        <ChatEdit
            canEdit={hasEdit}
            recipeCount={recipeCount}
            reqCoverage={reqCoverage}
            hasCoverage={hasCoverage}
            file={item.file}
            onAction={onAction}
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ChatBubble.propTypes = {
    item        : PropTypes.object.isRequired,
    isMine      : PropTypes.bool.isRequired,
    canEdit     : PropTypes.bool.isRequired,
    recipeCount : PropTypes.number.isRequired,
    reqCoverage : PropTypes.bool.isRequired,
    hasCoverage : PropTypes.bool.isRequired,
    onAction    : PropTypes.func,
};

export default ChatBubble;
