import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import DashboardAdminPage   from "Components/App/Dashboard/DashboardAdminPage";
import BusinessContainer    from "Components/App/Business/BusinessContainer";
import PharmacyContainer    from "Components/App/Business/PharmacyContainer";
import SubsidiaryContainer  from "Components/App/Business/SubsidiaryContainer";
import OrderList            from "Components/App/Order/OrderList";
import OrderPage            from "Components/App/Order/OrderPage";
import CatalogContainer     from "Components/App/Catalog/CatalogContainer";
import ClientList           from "Components/App/Client/ClientList";
import TransactionList      from "Components/App/Transaction/Transaction/TransactionList";
import TransactionPage      from "Components/App/Transaction/Transaction/TransactionPage";
import ProfileContainer     from "Components/App/Profile/ProfileContainer";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";



/**
 * The Finance Router
 * @returns {React.ReactElement}
 */
function FinanceRouter() {
    const { isFinance } = Store.useState("auth");


    // Do the Render
    if (!isFinance) {
        return <React.Fragment />;
    }
    return <Router type="ADMIN">
        <AdminRoute
            url="DASHBOARD"
            component={DashboardAdminPage}
        />
        <AdminRoute
            url="DASHBOARD_TAB"
            component={DashboardAdminPage}
        />

        <AdminRoute
            url="BUSINESS"
            component={BusinessContainer}
        />
        <AdminRoute
            url="PHARMACY"
            component={PharmacyContainer}
        />
        <AdminRoute
            url="PHARMACY_SUBSIDIARY"
            component={SubsidiaryContainer}
        />
        <AdminRoute
            url="SUBSIDIARY"
            component={SubsidiaryContainer}
        />

        <AdminRoute
            url="ORDERS"
            component={OrderList}
        />
        <AdminRoute
            url="ORDERS_TAB"
            component={OrderList}
        />
        <AdminRoute
            url="ORDER"
            component={OrderPage}
        />

        <AdminRoute
            url="CATALOG"
            component={CatalogContainer}
        />
        <AdminRoute
            url="CLIENTS"
            component={ClientList}
        />

        <AdminRoute
            url="TRANSACTIONS"
            component={TransactionList}
        />
        <AdminRoute
            url="TRANSACTION"
            component={TransactionPage}
        />

        <AdminRoute
            url="PROFILE"
            component={ProfileContainer}
        />
    </Router>;
}

export default FinanceRouter;
