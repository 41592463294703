import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";



// Styles
const Container = Styled.p`
    margin: 0;
    font-size: 14px;

    b {
        margin-right: 4px;
    }
`;



/**
 * The Order Text
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function OrderText(props) {
    const { className, isHidden, title, message, children } = props;


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <Container className={className}>
        <b>{NLS.get(title)}:</b>
        {message}
        {children}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
OrderText.propTypes = {
    className : PropTypes.string,
    isHidden  : PropTypes.bool,
    title     : PropTypes.string.isRequired,
    message   : PropTypes.string,
    children  : PropTypes.any,
};

export default OrderText;
