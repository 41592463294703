import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import OrderSection         from "../Utils/OrderSection";
import OrderText            from "../Utils/OrderText";

// Dashboard
import HyperLink            from "Dashboard/Components/Link/HyperLink";



/**
 * The Order Manage Client
 * @returns {React.ReactElement}
 */
function OrderManageClient() {
    const { isAnyAdmin } = Store.useState("auth");
    const { elem } = Store.useState("orderManage");


    // Do the Render
    return <OrderSection
        icon="client"
        message="ORDERS_CLIENT_INFO"
    >
        <OrderText
            title="GENERAL_NAME"
            message={elem.clientName}
        />
        <OrderText
            title="CLIENTS_DNI"
            message={elem.clientDni}
        />

        <OrderText
            isHidden={!isAnyAdmin || !elem.clientEmail}
            title="GENERAL_EMAIL"
        >
            <HyperLink
                message={elem.clientEmail}
                target="_blank"
                isEmail
            />
        </OrderText>

        <OrderText
            isHidden={!isAnyAdmin || !elem.clientPhone}
            title="GENERAL_CELLPHONE"
        >
            <HyperLink
                message={elem.clientPhone}
                target="_blank"
                isWhatsApp
            />
        </OrderText>
    </OrderSection>;
}

export default OrderManageClient;
