import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Responsive           from "Dashboard/Core/Responsive";
import Orders               from "Utils/Orders";

// Components
import OrderManageHeader    from "./Manage/OrderManageHeader";
import OrderManageData      from "./Manage/OrderManageData";
import OrderManageProducts  from "./Manage/OrderManageProducts";
import OrderManageDetails   from "./Manage/OrderManageDetails";

// Dashboard
import CircularLoader       from "Dashboard/Components/Loader/CircularLoader";



// Styles
const Container = Styled.main.attrs(({ withDetails }) => ({ withDetails }))`
    --main-details: calc(${(props) => props.withDetails ? "var(--details-width)" : "0px"} + var(--main-margin));

    display: flex;
    flex-grow: 2;
    flex-direction: column;
    width: calc(100vw - var(--sidebar-width) - var(--main-details) - var(--main-margin));

    @media (max-width: ${Responsive.WIDTH_FOR_DETAILS}px) {
        width: calc(100vw - var(--sidebar-width) - var(--main-details) - var(--main-margin));
    }

    @media (max-width: ${Responsive.WIDTH_FOR_MENU}px) {
        width: 100vw !important;
        overflow: auto;
    }
`;

const Content = Styled.section`
    display: flex;
    flex-direction: column;
    margin-right: var(--main-margin);
    margin-bottom: var(--main-margin);
    border-radius: var(--main-radius);
    background-color: var(--content-color);
`;



/**
 * The Order Manage
 * @returns {React.ReactElement}
 */
function OrderManage() {
    const { loading } = Store.useState("orderManage");


    // Load the Data
    Orders.useLoader();


    // Do the Render
    return <>
        <Container withDetails={!loading}>
            <Content>
                <OrderManageHeader />
                {loading && <CircularLoader />}
                {!loading && <OrderManageData />}
            </Content>
            {!loading && <OrderManageProducts />}
        </Container>

        {!loading && <OrderManageDetails />}
    </>;
}

export default OrderManage;
