import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { User }             from "Utils/API";



// The initial State
const initialState = {
    loading                 : true,
    error                   : false,
    edition                 : 0,
    canCreate               : false,
    canEdit                 : false,
    canFilter               : false,
    canImport               : false,
    canExport               : false,
    list                    : [],
    total                   : 0,
    elem                    : {},
    pharmacies              : [],
    subsidiaries            : [],
    subsidiariesPerPharmacy : {},
    accesses                : [],
    statuses                : [],
    filters                 : {},
    hasFilters              : false,
    sort                    : {
        orderBy  : "fullName",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "USER_LOADING" });
    },

    /**
     * Fetches the User List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        Navigate.unsetParams(params);
        if (type === "PHARMACY") {
            params.pharmacyID = elemID;
        } else if (type === "SUBSIDIARY") {
            params.subsidiaryID = elemID;
        }

        const data   = await User.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "USER_LIST", data });
    },

    /**
     * Fetches a single User
     * @param {Function} dispatch
     * @param {Number}   userID
     * @returns {Promise}
     */
    async fetchElem(dispatch, userID) {
        const data = await User.getOne({ userID });
        dispatch({ type : "USER_ELEM", data });
    },

    /**
     * Fetches the User Edit data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchEditData(dispatch) {
        const data = await User.getEditData();
        dispatch({ type : "USER_EDIT", data });
    },

    /**
     * Edits/Creates a User
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    async editElem(dispatch, data) {
        return await User.edit(data);
    },

    /**
     * Deletes a User
     * @param {Function} dispatch
     * @param {Number}   userID
     * @returns {Promise}
     */
    deleteElem(dispatch, userID) {
        return User.delete({ userID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    const subsidiaries = elem.subsidiaries.filter(({ subsidiaryID }) => !!subsidiaryID);

    elem.pharmacyID     = [ ...new Set(elem.subsidiaries.map(({ pharmacyID }) => pharmacyID)) ];
    elem.pharmacyText   = [ ...new Set(elem.subsidiaries.map(({ pharmacyName }) => pharmacyName)) ].join(", ");
    elem.subsidiaryID   = subsidiaries.map(({ subsidiaryID }) => subsidiaryID);
    elem.subsidiaryText = subsidiaries.map(({ subsidiaryFullName }) => subsidiaryFullName).join(", ");
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "USER_LIST", "USER_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "USER_LOADING":
        return {
            ...state,
            loading                 : true,
        };

    case "USER_LIST":
        return {
            ...state,
            loading                 : false,
            error                   : false,
            canCreate               : action.data.canCreate,
            canEdit                 : action.data.canEdit,
            canFilter               : action.data.canFilter,
            canImport               : action.data.canImport,
            canExport               : action.data.canExport,
            list                    : Utils.parseList(action.data.list, parseElem),
            total                   : action.data.total,
            filters                 : action.data.filters,
            hasFilters              : !Utils.isEmpty(action.data.filters),
            sort                    : action.data.sort,
        };

    case "USER_ELEM":
        return {
            ...state,
            error                   : false,
            edition                 : state.edition + 1,
            elem                    : parseElem(action.data.elem),
            pharmacies              : action.data.pharmacies,
            subsidiaries            : action.data.subsidiaries,
            subsidiariesPerPharmacy : action.data.subsidiariesPerPharmacy,
            accesses                : action.data.accesses,
            statuses                : action.data.statuses,
        };

    case "USER_EDIT":
        return {
            ...state,
            error                   : false,
            edition                 : state.edition + 1,
            pharmacies              : action.data.pharmacies,
            subsidiaries            : action.data.subsidiaries,
            subsidiariesPerPharmacy : action.data.subsidiariesPerPharmacy,
            accesses                : action.data.accesses,
            statuses                : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
