import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Dashboard
import DialogHeader         from "Dashboard/Components/Dialog/DialogHeader";
import IconLink             from "Dashboard/Components/Link/IconLink";
import Image                from "Dashboard/Components/Common/Image";



// Styles
const Title = Styled.h2`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 0;
    text-align: center;
    font-family: var(--title-font);
    font-weight: var(--title-font-weight);
    font-size: 18px;
    color: var(--alternative-color);
`;



/**
 * The Process View Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProcessHeader(props) {
    const { onClose, onPrint } = props;

    const { elem } = Store.useState("orderManage");


    // Returns the title
    const title = React.useMemo(() => {
        if (elem.name) {
            return `${elem.name} - ${elem.clientName}`;
        }
        return "ORDERS_VIEW_TITLE";
    }, [ elem.name, elem.clientName ]);



    // Do the Render
    return <DialogHeader
        message={title}
        icon="order"
        onClose={() => onClose(false)}
        childrenOnEnd
    >
        <Title>
            <Image
                source={elem.subsidiaryImage}
                message={elem.subsidiaryFullName}
                size="30"
            />
            {elem.subsidiaryFullName}
        </Title>
        <IconLink
            icon="print"
            onClick={onPrint}
        />
    </DialogHeader>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProcessHeader.propTypes = {
    onClose : PropTypes.func.isRequired,
    onPrint : PropTypes.func.isRequired,
};

export default ProcessHeader;
