import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Gateway Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function GatewayEdit(props) {
    const { open, elemCode, onClose, onSubmit } = props;

    const { editElem } = Store.useAction("gateway");


    // The Initial Data
    const initialData = {
        gatewayCode : "",
        name        : "",
        isActive    : 0,
        feeType     : "",
        feeValue    : 0,
        hasFee      : 0,
        hasLink     : 0,
        position    : "",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("gateway", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("gateway", open, elemCode, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="gateway"
        title={NLS.format("GATEWAYS_EDIT_TEXT", elemCode)}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="toggle"
            name="isActive"
            label="GENERAL_IS_ACTIVE_FEM"
            value={data.isActive}
            error={errors.isActive}
            onChange={handleChange}
            withBorder
        />

        <InputField
            isHidden={!data.hasFee}
            type="double"
            label="GATEWAYS_FEE"
            error={errors.fee}
            onChange={handleChange}
            isRequired
        >
            <InputItem
                type="select"
                name="feeType"
                options="SELECT_FEE_TYPES"
                value={data.feeType}
                hideClear
            />
            <InputItem
                type="number"
                name="feeValue"
                value={data.feeValue}
                icon={data.feeType === "Fixed" ? "price" : ""}
                postIcon={data.feeType === "Percent" ? "percent" : ""}
                minValue={0}
                step={0.01}
            />
        </InputField>

        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
GatewayEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemCode : PropTypes.string,
};

export default GatewayEdit;
