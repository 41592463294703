import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { Commission }       from "Utils/API";



// The initial State
const initialState = {
    loading                 : true,
    error                   : false,
    edition                 : 0,
    canCreate               : false,
    canEdit                 : false,
    canFilter               : false,
    canImport               : false,
    canExport               : false,
    list                    : [],
    total                   : 0,
    elem                    : {},
    saleTypes               : [],
    pharmacies              : [],
    subsidiaries            : [],
    subsidiariesPerPharmacy : {},
    filters                 : {},
    hasFilters              : false,
    sort                    : {
        orderBy  : "withCoverage",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "COMMISSION_LOADING" });
    },

    /**
     * Fetches the Commission List
     * @param {Function} dispatch
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        const data   = await Commission.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "COMMISSION_LIST", data });
    },

    /**
     * Fetches a single Commission
     * @param {Function} dispatch
     * @param {Number}   commissionID
     * @returns {Promise}
     */
    async fetchElem(dispatch, commissionID) {
        const data = await Commission.getOne({ commissionID });
        dispatch({ type : "COMMISSION_ELEM", data });
    },

    /**
     * Fetches the Commission Edit data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchEditData(dispatch) {
        const data = await Commission.getEditData();
        dispatch({ type : "COMMISSION_EDIT", data });
    },

    /**
     * Edits/Creates a Commission
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    async editElem(dispatch, data) {
        return await Commission.edit(data);
    },

    /**
     * Deletes a Commission
     * @param {Function} dispatch
     * @param {Number}   commissionID
     * @returns {Promise}
     */
    deleteElem(dispatch, commissionID) {
        return Commission.delete({ commissionID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.withCoverageText = Utils.toYesNo(elem.withCoverage);
    elem.useSubTotalText  = Utils.toYesNo(elem.useSubTotal);
    elem.percentText      = Utils.formatNumber(elem.percent, 2) + "%";
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "COMMISSION_LIST", "COMMISSION_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "COMMISSION_LOADING":
        return {
            ...state,
            loading                 : true,
        };

    case "COMMISSION_LIST":
        return {
            ...state,
            loading                 : false,
            error                   : false,
            canCreate               : action.data.canCreate,
            canEdit                 : action.data.canEdit,
            canFilter               : action.data.canFilter,
            canImport               : action.data.canImport,
            canExport               : action.data.canExport,
            list                    : Utils.parseList(action.data.list, parseElem),
            total                   : action.data.total,
            filters                 : action.data.filters,
            hasFilters              : !Utils.isEmpty(action.data.filters),
            sort                    : action.data.sort,
        };

    case "COMMISSION_ELEM":
        return {
            ...state,
            error                   : false,
            edition                 : state.edition + 1,
            elem                    : parseElem(action.data.elem),
            saleTypes               : action.data.saleTypes,
            pharmacies              : action.data.pharmacies,
            subsidiaries            : action.data.subsidiaries,
            subsidiariesPerPharmacy : action.data.subsidiariesPerPharmacy,
        };

    case "COMMISSION_EDIT":
        return {
            ...state,
            error                   : false,
            edition                 : state.edition + 1,
            saleTypes               : action.data.saleTypes,
            pharmacies              : action.data.pharmacies,
            subsidiaries            : action.data.subsidiaries,
            subsidiariesPerPharmacy : action.data.subsidiariesPerPharmacy,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
