import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import { Order }            from "Utils/API";

// Components
import OrderFilter          from "./Dialogs/OrderFilter";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import Html                 from "Dashboard/Components/Common/Html";



/**
 * The Order List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function OrderList(props) {
    const { type } = props;
    const { load, loadTab, loadFilter } = useList("order", type, false);

    const navigate = Navigate.useGoto();
    const { pharmacyID, subsidiaryID, orderTab } = Navigate.useParams();

    const data = Store.useState("order");
    const { loading, canEdit, list, total, filters, sort } = data;


    // The References
    const timerRef = React.useRef(0);

    // The Current State
    const [ update,    setUpdate    ] = React.useState(0);
    const [ exporting, setExporting ] = React.useState(false);
    const [ action,    updateAction ] = Action.useAction();


    // Start and clear the Auto-update
    React.useEffect(() => {
        Utils.setUpdateTimeout(timerRef, setUpdate, update);

        return () => {
            Utils.clearTimeout(timerRef);
        };
    }, []);

    // Handle the Update
    React.useEffect(() => {
        if (Number.isInteger(orderTab)) {
            gotoUrl("ORDERS", "ALL", orderTab);
        } else if (orderTab) {
            const newTab = NLS.urlToKey(orderTab, "EFFECTIVE", "AGENT", "PENDING", "REJECTED", "CANCELED", "ALL");
            if (!newTab) {
                gotoUrl("ORDERS", "EFFECTIVE");
            } else {
                fetch(newTab);
            }
        } else {
            gotoUrl("ORDERS", "EFFECTIVE");
        }
    }, [ orderTab, update ]);

    // Goes to the Url
    const gotoUrl = (...urlParts) => {
        if (pharmacyID && subsidiaryID) {
            navigate("BUSINESS", "PHARMACIES", pharmacyID, "SUBSIDIARIES", subsidiaryID, ...urlParts);
        } else if (pharmacyID) {
            navigate("BUSINESS", "PHARMACIES", pharmacyID, ...urlParts);
        } else if (subsidiaryID) {
            navigate("BUSINESS", "SUBSIDIARIES", subsidiaryID, ...urlParts);
        } else {
            navigate(...urlParts);
        }
    };

    // Loads the data and sets an auto-update
    const fetch = (newTab) => {
        if (newTab !== sort.filter) {
            loadTab(newTab, 50, false);
        } else {
            load(sort, false);
        }

        Utils.setUpdateTimeout(timerRef, setUpdate, update);
    };


    // Handles the Action
    const handleAction = async (action, elemID) => {
        if (action.isTab) {
            updateAction();
            gotoUrl("ORDERS", elemID);
        } else if (action.isView) {
            updateAction();
            gotoUrl("ORDERS", orderTab, elemID);
        } else if (action.isSubsidiary) {
            updateAction();
            const subsidiaryID = Utils.getValue(list, "orderID", elemID, "subsidiaryID");
            gotoUrl("BUSINESS", "SUBSIDIARIES", subsidiaryID);
        } else if (action.isExport) {
            setExporting(true);
            updateAction();
            const source = Order.export({ ...filters, filter : sort.filter });
            await Utils.download(source, NLS.get("ORDERS_FILE_NAME"));
            setExporting(false);
        } else {
            updateAction(action, elemID);
        }
    };

    // Handles the Filter Submit
    const handleFilter = (filters) => {
        updateAction();
        loadFilter(filters);
    };


    // Variables
    const isEffective    = sort.filter === "EFFECTIVE";
    const isPending      = sort.filter === "PENDING";
    const isRejected     = sort.filter === "REJECTED";
    const isCanceled     = sort.filter === "CANCELED";
    const showSubsidiary = !subsidiaryID;
    const showCommission = (isEffective || isPending || isRejected);


    // Do the Render
    return <Main>
        <Header message="ORDERS_NAME" icon="order">
            <ActionList
                data={data}
                onAction={handleAction}
                isExporting={exporting}
            />
        </Header>
        <Content>
            <TabList selected={sort.filter} onAction={handleAction}>
                <TabItem message="ORDERS_EFFECTIVE" value="EFFECTIVE" />
                <TabItem message="ORDERS_AGENT"     value="AGENT"     />
                <TabItem message="ORDERS_PENDING"   value="PENDING"   />
                <TabItem message="ORDERS_REJECTED"  value="REJECTED"  />
                <TabItem message="ORDERS_CANCELED"  value="CANCELED"  />
                <TabItem message="GENERAL_ALL_FEM"  value="ALL"       />
            </TabList>
            <Table
                fetch={load}
                sort={sort}
                none="ORDERS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="createdTime"        message="GENERAL_CREATED_AT_FEM"    minWidth="160" maxWidth="160" isHidden={!filters.useCreatedTime} />
                    <TableHeader field="lastStateTime"      message="ORDERS_LAST_STATE_AT"      minWidth="160" maxWidth="160" isHidden={!!filters.useCreatedTime} />
                    <TableHeader field="orderNumber"        message="ORDERS_SINGULAR"           maxWidth="80" />
                    <TableHeader field="subsidiaryFullName" message="SUBSIDIARIES_SINGULAR"     isHidden={!showSubsidiary} />
                    <TableHeader field="clientFirstName"    message="CLIENTS_SINGULAR"          />
                    <TableHeader field="retirementTypeName" message="RETIREMENT_TYPES_SINGULAR" />
                    <TableHeader field="orderStateName"     message="GENERAL_STATUS"            isHidden={isCanceled} />
                    <TableHeader field="rejectReasonName"   message="REJECT_REASONS_SINGULAR"   isHidden={!isCanceled} />
                    <TableHeader field="rejectStateReason"  message="REJECT_REASONS_MESSAGE"    isHidden={!isCanceled} />
                    <TableHeader field="cancelReasonName"   message="CANCEL_REASONS_SINGULAR"   isHidden={!isCanceled} />
                    <TableHeader field="cancelStateReason"  message="CANCEL_REASONS_MESSAGE"    isHidden={!isCanceled} />
                    <TableHeader field="totalQuantity"      message="GENERAL_QUANTITY"          align="center" maxWidth="80" isHidden={isCanceled} />
                    <TableHeader field="billingTotal"       message="GENERAL_SUBTOTAL"          align="right" maxWidth="90" isHidden={isCanceled} />
                    <TableHeader field="netTotal"           message="GENERAL_TOTAL"             align="right" maxWidth="90" />
                    <TableHeader field="commission"         message="ORDERS_COMMISSION"         align="right" maxWidth="110" isHidden={!showCommission} />
                    <TableHeader field="mercadoPagoFee"     message="GENERAL_MP"                align="right" maxWidth="80" isHidden={!showCommission} />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.orderID} elemID={elem.orderID}>
                        <TableCell message={elem.createdTimeDate}    />
                        <TableCell message={elem.lastStateTimeDate}  />
                        <TableCell message={elem.numberText}         />
                        <TableCell message={elem.subsidiaryFullName} />
                        <TableCell message={elem.clientName}         />
                        <TableCell message={elem.retirementTypeName} />
                        <TableCell message={elem.orderStateName}     />
                        <TableCell message={elem.rejectReasonName}   />
                        <TableCell message={elem.rejectStateReason}  />
                        <TableCell message={elem.cancelReasonName}   />
                        <TableCell message={elem.cancelStateReason}  />
                        <TableCell message={elem.totalQuantity}      />
                        <TableCell><Html>{elem.billingTotalText}</Html></TableCell>
                        <TableCell><Html>{elem.netTotalText}</Html></TableCell>
                        <TableCell><Html>{elem.commissionText}</Html></TableCell>
                        <TableCell><Html>{elem.mercadoPagoText}</Html></TableCell>
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW"       message="ORDERS_VIEW_TITLE"     />
                    <TableAction action="SUBSIDIARY" message="SUBSIDIARIES_VIEW_TITLE" isHidden={!showSubsidiary} />
                </TableActionList>
            </Table>
        </Content>

        <OrderFilter
            open={action.isFilter}
            filters={filters}
            pharmacyID={pharmacyID}
            subsidiaryID={subsidiaryID}
            forCanceled={isCanceled}
            onSubmit={handleFilter}
            onClose={updateAction}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
OrderList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default OrderList;
