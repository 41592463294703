import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import ProcessSection       from "../Utils/ProcessSection";
import OrderStateList       from "Components/App/Order/View/OrderStateList";



// Styles
const Container = Styled(ProcessSection)`
    flex-grow: 2;
`;

const States = Styled.div`
    padding-top: 8px;
    padding-left: 8px;
`;



/**
 * The Process View States
 * @returns {React.ReactElement}
 */
function ProcessStates() {
    const { states } = Store.useState("orderManage");


    // Do the Render
    return <Container message="ORDERS_STATES_TRACKING">
        <States>
            <OrderStateList states={states} />
        </States>
    </Container>;
}

export default ProcessStates;
