import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Dashboard
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



// Styles
const Invoice = Styled(Columns)`
    grid-area: invoice;
    margin-top: 16px;
`;



/**
 * The Process View Invoice
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProcessInvoice(props) {
    const { data, errors, onChange } = props;

    const { elem } = Store.useState("orderManage");


    // Do the Render
    return <Invoice amount="2" isHidden={!elem.canEditData}>
        <InputField
            name="ticketNumber"
            label="ORDERS_TICKET_NUMBER"
            value={data.ticketNumber}
            error={errors.ticketNumber}
            onChange={onChange}
        />
        <InputField
            name="invoiceNumber"
            label="ORDERS_INVOICE_NUMBER"
            value={data.invoiceNumber}
            error={errors.invoiceNumber}
            onChange={onChange}
        />
    </Invoice>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProcessInvoice.propTypes = {
    data     : PropTypes.object.isRequired,
    errors   : PropTypes.object.isRequired,
    onChange : PropTypes.func.isRequired,
};

export default ProcessInvoice;
