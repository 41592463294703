import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { DashboardProcess } from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    elemError : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    list      : [],
    tooltips  : {},
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "DASHBOARD_PROCESS_LOADING" });
    },

    /**
     * Fetches the Process Dashboard
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchList(dispatch) {
        const data = await DashboardProcess.getAll();
        dispatch({ type : "DASHBOARD_PROCESS_LIST", data });
    },

    /**
     * Starts Using an Order
     * @param {Function} dispatch
     * @param {Number}   orderID
     * @returns {Promise}
     */
    startUsingElem(dispatch, orderID) {
        return DashboardProcess.startUsing({ orderID });
    },

    /**
     * Stops Using an Order
     * @param {Function} dispatch
     * @param {Number}   orderID
     * @returns {Promise}
     */
    stopUsingElem(dispatch, orderID) {
        return DashboardProcess.stopUsing({ orderID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @param {Object} minutes
 * @returns {Object}
 */
function parseElem(elem, minutes) {
    elem.createdDate = DateTime.formatString(elem.createdTime);
    elem.numberText  = `#${elem.orderNumber}`;
    elem.name        = NLS.format("ORDERS_ITS_NAME", elem.orderNumber);
    elem.productText = NLS.pluralize("ORDERS_PRODUCTS", elem.totalProducts);

    elem.color = "";
    if (elem.isColumnPending) {
        elem.color = DateTime.getExpiredColor(elem.createdTime, minutes.processYellow, minutes.processRed);
    } else if (elem.isColumnAgentQueue) {
        elem.color = DateTime.getExpiredColor(elem.createdTime, minutes.agentYellow, minutes.agentRed);
    }
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "DASHBOARD_PROCESS_LIST")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "DASHBOARD_PROCESS_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "DASHBOARD_PROCESS_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            list      : Utils.parseList(action.data.list, (elem) => parseElem(elem, action.data.minutes)),
            tooltips  : action.data.tooltips,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
