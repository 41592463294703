import React                from "react";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import ChangeCatalogView     from "./ChangeCatalogView";
import ChangeCatalogProcess  from "./ChangeCatalogProcess";
import ChangeCatalogValidate from "./ChangeCatalogValidate";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Filter               from "Dashboard/Components/Filter/Filter";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";



/**
 * The Change Catalog List
 * @returns {React.ReactElement}
 */
function ChangeCatalogList() {
    const { load, loadFilter, loadTab } = useList("changeCatalog", "", false);

    const { changeCatalogTab } = Navigate.useParams();
    const navigate = Navigate.useGoto();

    const data = Store.useState("changeCatalog");
    const { loading, canEdit, canValidate, list, total, filters, sort } = data;

    const { startUsingElem, stopUsingElem, multiValidateElem } = Store.useAction("changeCatalog");


    // The References
    const timerRef = React.useRef(0);

    // The Current State
    const [ checked, setChecked ] = React.useState([]);
    const [ update,  setUpdate  ] = React.useState(0);

    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handle the Auto-update and Unload
    React.useEffect(() => {
        Utils.setUpdateTimeout(timerRef, setUpdate, update);

        if (elemID) {
            window.addEventListener("beforeunload", () => stopUsingElem(elemID));
        }
        return () => {
            Utils.clearTimeout(timerRef);
            window.removeEventListener("beforeunload", () => stopUsingElem(elemID));
        };
    }, [ elemID ]);

    // Handle the Update
    React.useEffect(() => {
        if (changeCatalogTab) {
            fetch(changeCatalogTab);
        } else {
            navigate("CATALOG", "CHANGE_CATALOGS", "PROCESS");
        }
    }, [ changeCatalogTab, update ]);

    // Loads the data and sets an auto-update
    const fetch = (changeCatalogTab) => {
        const newTab = NLS.urlToKey(changeCatalogTab, "PROCESS", "VALIDATE", "COMPLETE");
        if (newTab !== sort.filter) {
            loadTab(newTab, 50, false);
        } else {
            load(sort, false);
        }

        Utils.setUpdateTimeout(timerRef, setUpdate, update);
    };


    // Handles the Action
    const handleAction = async (action, elemID) => {
        if (action.isTab) {
            endAction();
            navigate("CATALOG", "CHANGE_CATALOGS", elemID);
        } else if (action.isProcess) {
            const result = await startUsingElem(elemID);
            if (!result.error) {
                startAction(action, elemID);
            }
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Close
    const handleClose = () => {
        stopUsingElem(elemID);
        endAction();
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch(changeCatalogTab);
    };

    // Handles the Multi Validate Submit
    const handleMultiValidate = async () => {
        const result = await multiValidateElem(elemID || checked);
        if (result.success) {
            setChecked([]);
            fetch(changeCatalogTab);
        }
        endAction();
    };


    // Returns true if it can't view the Change
    const cantView = (elemID) => {
        const elem = Utils.getValue(list, "changeCatalogID", elemID);
        return !(!canEdit || (elem.isProcessed && elem.isValidated));
    };

    // Returns true if it can't process the Change
    const cantProcess = (elemID) => {
        const elem = Utils.getValue(list, "changeCatalogID", elemID);
        return !(canEdit && !elem.isProcessed);
    };

    // Returns true if it can't validate the Change
    const cantValidate = (elemID) => {
        const elem = Utils.getValue(list, "changeCatalogID", elemID);
        return !(canValidate && elem.isProcessed && !elem.isValidated);
    };



    // Variables
    const isProcess        = sort.filter === "PROCESS";
    const isValidate       = sort.filter === "VALIDATE";
    const showValidate     = canValidate && !isProcess;
    const itemAmount       = checked.length;
    const canMultiValidate = itemAmount > 0 && isValidate;


    // Do the Render
    return <Main>
        <Header message="CHANGE_CATALOGS_NAME" icon="change-catalog">
            <ActionList data={data} onAction={startAction}>
                <ActionItem
                    isHidden={!canMultiValidate}
                    action="MULTI"
                    message="CHANGE_CATALOGS_MULTI_VALIDATE_TITLE"
                />
            </ActionList>
        </Header>
        <Content>
            <TabList
                isHidden={!canValidate}
                selected={sort.filter}
                onAction={handleAction}
            >
                <TabItem message="CHANGE_CATALOGS_PROCESS"  value="PROCESS"  />
                <TabItem message="CHANGE_CATALOGS_VALIDATE" value="VALIDATE" />
                <TabItem message="CHANGE_CATALOGS_COMPLETE" value="COMPLETE" />
            </TabList>
            <Filter
                onFilter={loadFilter}
                values={filters}
                hasSearch
            />
            <Table
                fetch={load}
                sort={sort}
                none="CHANGE_CATALOGS_NONE_AVAILABLE"
                isLoading={loading}
                checked={checked}
                noSorting={!canValidate}
                setChecked={isValidate ? setChecked : null}
                hasCheckAll
            >
                <TableHead>
                    <TableHeader field="createdTime"                message="GENERAL_CREATED_AT"           maxWidth="180" hideCircle={!isProcess} />
                    <TableHeader field="modificationType"           message="GENERAL_TYPE"                 maxWidth="80" />
                    <TableHeader field="module"                     message="CHANGE_CATALOGS_MODULE"       />
                    <TableHeader field="alfabetaID"                 message="GENERAL_ALFABETA"             maxWidth="80" />
                    <TableHeader field="oldNameAlfabeta"            message="CHANGE_CATALOGS_OLD_ALFABETA" />
                    <TableHeader field="newNameAlfabeta"            message="CHANGE_CATALOGS_NEW_ALFABETA" />
                    <TableHeader field="oldNameBenvida"             message="CHANGE_CATALOGS_OLD_BENVIDA"  />
                    <TableHeader field="newNameBenvida"             message="CHANGE_CATALOGS_NEW_BENVIDA"  isHidden={!showValidate} />
                    <TableHeader field="processTime"                message="CHANGE_CATALOGS_PROCESSED_AT" maxWidth="180" isHidden={!showValidate} />
                    <TableHeader field="processCredentialFirstName" message="CHANGE_CATALOGS_PROCESSED_BY" isHidden={!showValidate} />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.changeCatalogID} elemID={elem.changeCatalogID}>
                        <TableCell message={elem.createdDate}           circle={elem.usingCircle} />
                        <TableCell message={elem.modificationTypeText}  />
                        <TableCell message={elem.moduleText}            />
                        <TableCell message={elem.alfabetaID}            />
                        <TableCell message={elem.oldNameAlfabeta}       />
                        <TableCell message={elem.newNameAlfabeta}       />
                        <TableCell message={elem.oldNameBenvida}        />
                        <TableCell message={elem.newNameBenvida}        />
                        <TableCell message={elem.processDate}           />
                        <TableCell message={elem.processCredentialName} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW"     message="CHANGE_CATALOGS_VIEW_TITLE"     hide={cantView} />
                    <TableAction action="PROCESS"  message="CHANGE_CATALOGS_PROCESS_TITLE"  hide={cantProcess} />
                    <TableAction action="VALIDATE" message="CHANGE_CATALOGS_VALIDATE_TITLE" hide={cantValidate} />
                </TableActionList>
            </Table>
        </Content>

        <ChangeCatalogView
            open={action.isView}
            elemID={elemID}
            onClose={endAction}
        />
        <ChangeCatalogProcess
            open={action.isProcess}
            elemID={elemID}
            onClose={handleClose}
            onSubmit={handleEdit}
        />
        <ChangeCatalogValidate
            open={action.isValidate}
            elemID={elemID}
            onClose={endAction}
            onSubmit={handleEdit}
        />

        <ConfirmDialog
            open={action.isMulti}
            icon="change-catalog"
            title="CHANGE_CATALOGS_MULTI_VALIDATE_TITLE"
            message={NLS.pluralize("CHANGE_CATALOGS_MULTI_VALIDATE", itemAmount)}
            onSubmit={handleMultiValidate}
            onClose={endAction}
        />
    </Main>;
}

export default ChangeCatalogList;
