import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import { Product }          from "Utils/API";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";
import Icon                 from "Dashboard/Components/Common/Icon";
import PromptDialog         from "Dashboard/Components/Dialogs/PromptDialog";



// Styles
const Input = Styled(InputField)`
    .inputfield-label {
        left: 8px;
        padding: 0;
        text-overflow: clip;
    }
    .input-content {
        padding-left: 8px;
        padding-right: 8px;
    }

    .inputfield-toggle .input-content {
        padding-left: 4px;
        font-size: 12px;
    }
    .inputfield-toggle .input-content label span {
        margin-right: 4px;
    }

    .inputfield-select .input-content {
        gap: 0;
    }
`;

const Help = Styled(Icon)`
    position: absolute;
    top: 1px;
    right: 1px;
    font-size: 18px;
    color: var(--primary-color);
    z-index: 2;
    cursor: pointer;
`;



/**
 * The Order Products
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function OrderProducts(props) {
    const {
        isHidden, saleTypes, tooltips,
        unitPrices, pamiPrices, prices, subTotals,
        data, errors, onChange,
        canEditProducts, canWaitProducts, canEditPrices,
    } = props;


    // The Current State
    const [ showVoucher, setShowVoucher ] = React.useState(false);
    const [ voucherDate, setVoucherDate ] = React.useState("");
    const [ index,       setIndex       ] = React.useState(-1);


    // Handles the Change
    const handleChange = (index, name, newValue, id, newID, newData) => {
        const newPrices     = newData?.data ?? {};
        const product       = JSON.parse(data.products)[index];
        const productType   = name === "productType" ? newValue : product.productType;
        const isPAMI        = productType === "Pami";
        const price         = Number(product.price ?? 0);
        let   quantity      = Math.max(Number(product.quantity ?? 1), 1);
        let   discountType  = product.discountType ?? "";
        let   discountValue = Number(product.discountValue ?? 0);

        let unitPrice = price;
        if (productType === "Manual") {
            unitPrice = unitPrices.current[index] ?? price;
        } else if (productType === "Pami") {
            unitPrice = pamiPrices.current[index] ?? price;
        }

        switch (name) {
        case "name":
            unitPrice                 = isPAMI && newPrices.pamiPrice ? newPrices.pamiPrice : newPrices.price;
            unitPrices.current[index] = newPrices.price;
            pamiPrices.current[index] = newPrices.pamiPrice || newPrices.price;
            break;
        case "quantity":
            quantity = Math.max(Number(newValue), 1);
            break;
        case "price":
            unitPrice = Number(newValue);
            break;
        case "discountType":
            discountType = newValue;
            break;
        case "discountValue":
            discountValue = Number(newValue);
            break;
        default:
        }

        const totalPrice = quantity * unitPrice;

        let discountPrice = 0;
        if (discountType === "Percent") {
            const percent = Utils.clamp(discountValue / 100, 0, 1);
            discountPrice = totalPrice * -percent;
        } else if (discountType === "Value") {
            discountPrice = Utils.clamp(discountValue, 0, totalPrice) * -1;
        }

        prices.current[index]    = Utils.round(totalPrice, 2);
        subTotals.current[index] = Utils.round(totalPrice + discountPrice, 2);
    };


    // Returns the after Title text
    const getAfterTitle = (elem, index) => {
        if (!elem.voucherTimeDate) {
            return "";
        }

        const date = DateTime.formatDate(elem.voucherTimeDate, "dashes");
        return NLS.format("DASHBOARD_PROCESS_VOUCHER_DATE", date);
    };

    // Handles the Voucher Open
    const handleVoucherOpen = (index) => {
        setShowVoucher(true);
        setIndex(index);
        const products = JSON.parse(data.products);
        setVoucherDate(products[index].voucherTimeDate ?? "");
    };

    // Handles the Voucher Set
    const handleVoucherSet = (date) => {
        setShowVoucher(false);
        const products = JSON.parse(data.products);
        products[index].voucherTimeDate = date;
        onChange("products", JSON.stringify(products));
    };

    // Handles the Voucher Close
    const handleVoucherClose = () => {
        setShowVoucher(false);
        setIndex(-1);
        setVoucherDate("");
    };


    // Variables
    const productTooltip  = Commons.getTooltip(tooltips, "Product");
    const discountTooltip = Commons.getTooltip(tooltips, "Discount");


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <>
        <Input
            type="fields"
            name="products"
            title="PRODUCTS_TITLE"
            getAfterTitle={getAfterTitle}
            addButton="PRODUCTS_ADD_PRODUCT"
            columns="16"
            value={data.products}
            errors={errors}
            onChange={onChange}
            extraIcon={canWaitProducts ? "voucher" : ""}
            onExtraIcon={handleVoucherOpen}
            noneText="PRODUCTS_NONE_AVAILABLE"
            isDisabled={!canEditProducts}
            allowEmpty
        >
            <InputItem
                columns="3"
                type="select"
                name="productType"
                label="GENERAL_TYPE"
                options="SELECT_PRODUCT_TYPES"
                value="Manual"
                onChange={handleChange}
                hideClear
            />
            <InputItem
                hide={(elem) => elem.productType === "New"}
                columns="8"
                type="suggest"
                name="name"
                label="PRODUCTS_SINGULAR"
                suggestID="productID"
                suggestFetch={Product.search}
                suggestWidth={500}
                onChange={handleChange}
                hideClear
                component={<Help
                    isHidden={!productTooltip}
                    icon="help"
                    tooltip={productTooltip}
                    tooltipWidth={300}
                    tooltipDelay={0.2}
                />}
            />
            <InputItem
                hide={(elem) => elem.productType !== "New"}
                columns="5"
                name="productName"
                label="PRODUCTS_SINGULAR"
                component={<Help
                    isHidden={!productTooltip}
                    icon="help"
                    tooltip={productTooltip}
                    tooltipWidth={300}
                    tooltipDelay={0.2}
                />}
            />
            <InputItem
                hide={(elem) => elem.productType !== "New"}
                columns="3"
                type="select"
                name="saleTypeID"
                label="SALE_TYPES_SINGULAR"
                options={saleTypes}
                shrinkLabel
                hideClear
            />
            <InputItem
                columns="2"
                type="number"
                name="quantity"
                label="GENERAL_QUANTITY"
                onChange={handleChange}
                value="1"
                minValue={0}
                shrinkLabel
            />
            <InputItem
                columns="3"
                type="number"
                name="price"
                label="GENERAL_PRICE"
                icon="price"
                getType={(elem) => canEditPrices || elem.productType === "New" ? "number" : "text"}
                getValue={(index, elem) => canEditPrices || elem.productType === "New" ? elem.price : (prices.current[index] ?? 0)}
                getDisabled={(elem) => !canEditPrices && elem.productType !== "New"}
                onChange={handleChange}
                shrinkLabel
            />

            <InputItem
                getColumns={(elem) => elem.discountType === "None" ? "13" : "3"}
                type="select"
                name="discountType"
                label="ORDERS_DISCOUNT"
                options="SELECT_DISCOUNT_TYPES"
                value="None"
                onChange={handleChange}
                component={<Help
                    isHidden={!discountTooltip}
                    icon="help"
                    tooltip={discountTooltip}
                    tooltipWidth={300}
                />}
                shrinkLabel
                hideClear
            />
            <InputItem
                hide={(elem) => elem.discountType === "None"}
                columns="5"
                type="number"
                name="discountValue"
                label="ORDERS_DISCOUNT_VALUE"
                minValue={0}
                getIcon={(elem) => elem.discountType === "Percent" ? "" : "price"}
                getPostIcon={(elem) => elem.discountType === "Percent" ? "percent" : ""}
                onChange={handleChange}
                shrinkLabel
            />
            <InputItem
                hide={(elem) => elem.discountType === "None"}
                columns="5"
                type="select"
                name="discountOrigin"
                label="ORDERS_DISCOUNT_ORIGIN"
                options="SELECT_DISCOUNT_ORIGINS"
                hideClear
            />
            <InputItem
                columns="3"
                type="text"
                name="subTotal"
                label="GENERAL_SUBTOTAL"
                icon="price"
                getValue={(index) => subTotals.current[index] ?? 0}
                shrinkLabel
                isDisabled
            />
        </Input>

        <PromptDialog
            open={showVoucher}
            icon="voucher"
            title="DASHBOARD_PROCESS_VOUCHER_TITLE"
            message="DASHBOARD_PROCESS_VOUCHER_TEXT"
            content={index + 1}
            initialValue={voucherDate}
            inputType="date"
            onSubmit={handleVoucherSet}
            onClose={handleVoucherClose}
            isOptional
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
OrderProducts.propTypes = {
    isHidden        : PropTypes.bool,
    tooltips        : PropTypes.object,
    saleTypes       : PropTypes.array.isRequired,
    unitPrices      : PropTypes.object.isRequired,
    pamiPrices      : PropTypes.object.isRequired,
    prices          : PropTypes.object.isRequired,
    subTotals       : PropTypes.object.isRequired,
    data            : PropTypes.object.isRequired,
    errors          : PropTypes.object.isRequired,
    onChange        : PropTypes.func.isRequired,
    canEditProducts : PropTypes.bool,
    canWaitProducts : PropTypes.bool,
    canEditPrices   : PropTypes.bool,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
OrderProducts.defaultProps = {
    isHidden        : false,
    tooltips        : {},
    canEditProducts : false,
    canWaitProducts : false,
    canEditPrices   : false,
};

export default OrderProducts;
