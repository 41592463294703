import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Commons              from "Utils/Commons";
import Orders               from "Utils/Orders";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import Image                from "Dashboard/Components/Common/Image";



// Styles
const Title = Styled.h2`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 0;
    text-align: center;
    font-family: var(--title-font);
    font-weight: var(--title-font-weight);
    font-size: 18px;
    margin-right: 24px;
`;



/**
 * The Order Manage Header
 * @returns {React.ReactElement}
 */
function OrderManageHeader() {
    const { orderID } = Navigate.useParams();
    const parent      = Navigate.useParent(1);

    const { error, elem, products, states } = Store.useState("orderManage");


    // Load the Data
    Orders.useLoader(orderID);

    // Handle the Print
    const handlePrint = () => {
        Commons.printOrder(elem, products, states);
    };



    // Do the Render
    return <Header
        message={elem.name || "ORDERS_SINGULAR"}
        icon="order"
        href={parent}
    >
        <Title>
            <Image
                source={elem.subsidiaryImage}
                message={elem.subsidiaryFullName}
                size="30"
            />
            {elem.subsidiaryFullName}
        </Title>

        <ActionList>
            <ActionItem
                isHidden={error}
                icon="print"
                message="GENERAL_PRINT"
                onClick={handlePrint}
            />
        </ActionList>
    </Header>;
}

export default OrderManageHeader;
