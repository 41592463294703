import React                from "react";
import Styled               from "styled-components";

// Components
import OrderManageChat      from "Components/App/Order/Manage/OrderManageChat";



// Styles
const Container = Styled.aside`
    --input-border-color: var(--border-color-light);

    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    gap: var(--main-gap);
    width: var(--chat-width);
    height: var(--dialog-height);
    padding: 16px;
    margin-left: 16px;
    background-color: white;
    border-radius: var(--dialog-radius);
`;




/**
 * The Process View Chat
 * @returns {React.ReactElement}
 */
function ProcessChat() {

    // Do the Render
    return <Container>
        <OrderManageChat />
    </Container>;
}

export default ProcessChat;
