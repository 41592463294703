import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Container = Styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: var(--main-gap);
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
`;

const Title = Styled.h3`
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    color: var(--alternative-color);
    font-family: var(--title-font);
    font-weight: var(--title-font-weight);
    font-size: 18px;

    :empty {
        display: none;
    }
`;



/**
 * The Order Section
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function OrderSection(props) {
    const { className, icon, message, children } = props;


    // Do the Render
    return <Container className={className}>
        <Title>
            <Icon icon={icon} />
            {NLS.get(message)}
        </Title>
        {children}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
OrderSection.propTypes = {
    className : PropTypes.string,
    message   : PropTypes.string,
    icon      : PropTypes.string,
    children  : PropTypes.any,
};

export default OrderSection;
