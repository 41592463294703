import React                from "react";
import Styled               from "styled-components";

// Components
import OrderManageChat      from "./OrderManageChat";
import ProcessTotals        from "Components/App/Dashboard/Process/Components/ProcessTotals";

// Dashboard
import Details              from "Dashboard/Components/Details/Details";



// Styles
const Content = Styled.div`
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    gap: var(--main-gap);
    padding: 16px;
`;



/**
 * The Order Details
 * @returns {React.ReactElement}
 */
function OrderDetails() {

    // Do the Render
    return <Details>
        <Content>
            <OrderManageChat />
            <ProcessTotals
                subTotal={0}
                discount={0}
            />
        </Content>
    </Details>;
}

export default OrderDetails;
