import Navigate from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import React from "react";



/**
 * Initializes the Order Totals
 * @param {Object[]} products
 * @returns {{unitPrices: Number[], prices: Number[], subTotals: Number[]}}
 */
function initializeTotals(products) {
    const unitPrices = [];
    const prices     = [];
    const subTotals  = [];

    for (const [ index, product ] of Object.entries(products)) {
        const { totalPrice, discountPrice } = calcProductTotals(product, product.price);

        unitPrices[index] = product.price;
        prices[index]     = Utils.round(totalPrice, 2);
        subTotals[index]  = Utils.round(totalPrice + discountPrice, 2);
    }
    return { unitPrices, prices, subTotals };
}

/**
 * Calculates the Order Totals
 * @param {String} dataProducts
 * @param {Object} unitPrices
 * @param {Object} pamiPrices
 * @returns {{subTotal: Number, discount: Number}}
 */
function calcTotals(dataProducts, unitPrices, pamiPrices) {
    const products = JSON.parse(dataProducts);
    let   subTotal = 0;
    let   discount = 0;

    for (const [ index, product ] of products.entries()) {
        let basePrice = unitPrices.current[index] ?? 0;
        if (product.productType === "Pami") {
            basePrice = pamiPrices.current[index] ?? basePrice;
        }
        const { totalPrice, discountPrice } = calcProductTotals(product, basePrice);

        subTotal += totalPrice;
        discount += discountPrice;
    }

    return { subTotal, discount };
}

/**
 * Calculates the Product Totals
 * @param {Object} product
 * @param {Number} basePrice
 * @returns {{totalPrice: Number, discountPrice: Number}}
 */
function calcProductTotals(product, basePrice) {
    const quantity   = Math.max(Number(product.quantity ?? 1), 1);
    const price      = Number(product.price ?? 0);
    const unitPrice  = product.productType === "New" ? price : (basePrice ?? price);
    const totalPrice = quantity * unitPrice;

    const discountValue = Number(product.discountValue ?? 0);
    let   discountPrice = 0;
    if (product.discountType === "Percent") {
        const percent = Utils.clamp(discountValue / 100, 0, 1);
        discountPrice = totalPrice * -percent;
    } else if (product.discountType === "Value") {
        discountPrice = Utils.clamp(discountValue, 0, totalPrice) * -1;
    }

    return { totalPrice, discountPrice };
}

/**
 * Encodes the Order Products
 * @param {Object[]} products
 * @returns {String}
 */
function encodeProducts(products) {
    if (!products.length) {
        return JSON.stringify([{
            orderProductID  : 0,
            productID       : 0,
            saleTypeID      : 0,
            productType     : "Manual",
            productName     : "",
            name            : "",
            quantity        : 1,
            price           : 0,
            discountType    : "None",
            discountOrigin  : "",
            discountValue   : 0,
            discountAmount  : 0,
            subTotal        : 0,
            voucherTimeDate : "",
        }]);
    }

    const result = [];
    for (const elem of products) {
        result.push({
            orderProductID  : elem.orderProductID,
            productID       : elem.productID,
            saleTypeID      : elem.saleTypeID,
            productType     : elem.productType,
            productName     : elem.productName,
            name            : `${elem.benvidaID} - ${elem.name}`,
            quantity        : elem.quantity,
            price           : elem.price,
            discountType    : elem.discountType || "None",
            discountOrigin  : elem.discountOrigin,
            discountValue   : elem.discountValue,
            discountAmount  : elem.discountAmount,
            subTotal        : 0,
            voucherTimeDate : DateTime.formatIf(elem.voucherTime, "dashesReverse"),
        });
    }
    return JSON.stringify(result);
}


/**
 * Handles the Order Loader
 * @returns {Void}
 */
function useLoader() {
    const { orderID } = Navigate.useParams();

    const { edition, elem, products } = Store.useState("orderManage");
    const { fetchElem } = Store.useAction("orderManage");

    const { setInitialData } = Store.useAction("orderState");

    const initialized = React.useRef(false);


    // Fetch the Data
    const fetchData = async () => {
        if (!initialized.current) {
            await fetchElem(orderID);
            initialized.current = true;
        }
    };

    // Load the Data
    React.useEffect(() => {
        fetchData();
        return () => {
            initialized.current = false;
        };
    }, []);

    // Data Updated
    React.useEffect(() => {
        if (edition) {
            const { unitPrices, prices, subTotals } = initializeTotals(products);
            setInitialData(elem, products, unitPrices, prices, subTotals);
        }
    }, [ edition ]);
}

// eslint-disable-next-line valid-jsdoc
/**
 * Handles the Order Change
 * @returns {(name: String, value: String, secondName?: String, secondValue?: String) => void}
 */
function useHandleChange() {
    const { errors } = Store.useState("orderState");
    const { setData, setErrors } = Store.useAction("orderState");

    return (name, value, secondName, secondValue) => {
        const fields = { [name] : value };
        if (secondName) {
            fields[secondName] = secondValue;
        }

        setData(fields);
        const removeErrors = { [name] : "" };

        // Handle the Errors of a Field input
        try {
            const data = JSON.parse(value);
            for (const key of Object.keys(errors)) {
                if (!key.startsWith(name)) {
                    continue;
                }
                const parts = key.split("-");
                if (parts.length !== 3) {
                    continue;
                }
                if (data[parts[1]]?.[parts[2]]) {
                    removeErrors[key] = "";
                }
            }
        } catch {
            // Do Nothing
        }
        setErrors(removeErrors);
    };
}

// eslint-disable-next-line valid-jsdoc
/**
 * Handles the Order Chat Action
 * @returns {(type: String, index: Number, file: String) => void}
 */
function useChatAction() {
    const { elem } = Store.useState("orderManage");
    const { editRecipe, editCoverage } = Store.useAction("orderManage");

    const { reqCoverageFile } = Store.useState("orderState");
    const { setCoverageFile } = Store.useAction("orderState");


    return (type, index, file) => {
        if (type === "recipe") {
            editRecipe(elem.orderID, index, file);
        } else if (reqCoverageFile) {
            setCoverageFile(file);
        } else {
            editCoverage(elem.orderID, file);
        }
    };
}




// The public API
export default {
    initializeTotals,
    calcTotals,
    encodeProducts,

    useLoader,
    useHandleChange,
    useChatAction,
};
