import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Commons              from "Utils/Commons";

// Components
import ChatEmpty            from "Components/App/Order/Chat/ChatEmpty";
import ChatDay              from "Components/App/Order/Chat/ChatDay";
import ChatBubble           from "Components/App/Order/Chat/ChatBubble";

// Dashboard
import Html                 from "Dashboard/Components/Common/Html";



// Styles
const Content = Styled.section.attrs(({ isMine }) => ({ isMine }))`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.isMine ? "flex-end" : "flex-start"};
`;

const User = Styled.h3`
    margin: 0;
    padding: 2px 24px;
    font-size: 14px;
`;

const Action = Styled(Html)`
    padding: 0 12px;
    text-align: center;
    color: var(--darkest-gray);
`;



/**
 * The Chat Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ChatContent(props) {
    const { items, canChat, canEdit, recipeCount, reqCoverage, hasCoverage, onAction } = props;


    // Do the Render
    if (!items.length) {
        return <ChatEmpty canChat={canChat} />;
    }

    return <>
        {items.map((elem, key) => <React.Fragment key={key}>
            <ChatDay dayName={elem.dayName} />
            {!!elem.messages.length && <Content isMine={elem.isMine}>
                <User>{elem.userName}</User>
                {elem.messages.map((item) => <ChatBubble
                    key={item.id}
                    item={item}
                    isMine={elem.isMine}
                    canEdit={canEdit}
                    recipeCount={recipeCount}
                    reqCoverage={reqCoverage}
                    hasCoverage={hasCoverage}
                    onAction={onAction}
                />)}
            </Content>}
            {elem.actions.map((elem) => <Action
                key={elem.id}
                content={Commons.getActionText(elem)}
            />)}
        </React.Fragment>)}
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ChatContent.propTypes = {
    items       : PropTypes.array.isRequired,
    canChat     : PropTypes.bool,
    canEdit     : PropTypes.bool,
    recipeCount : PropTypes.number,
    reqCoverage : PropTypes.bool,
    hasCoverage : PropTypes.bool,
    onAction    : PropTypes.func,
};

/**
 * The Default Properties
 * @typedef {Object} defaultProps
 */
ChatContent.defaultProps = {
    canChat     : false,
    canEdit     : false,
    recipeCount : 0,
    reqCoverage : false,
    hasCoverage : false,
};

export default ChatContent;
