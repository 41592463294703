import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Logistic Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function LogisticEdit(props) {
    const { open, elemCode, onClose, onSubmit } = props;

    const { editElem } = Store.useAction("logistic");


    // The Initial Data
    const initialData = {
        logisticCode : "",
        isActive     : 0,
        feeType      : "",
        feeValue     : 0,
        minKM        : 0,
        maxKM        : 0,
        timeTables   : "[{}]",
        position     : "",
    };

    // Handles the Set
    const handleSet = (elem) => {
        setElem({
            ...elem,
            timeTables : elem?.timeTables?.length ? JSON.stringify(elem.timeTables) : "[{}]",
        });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("logistic", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("logistic", open, elemCode, null, handleSet);



    // Do the Render
    return <EditDialog
        open={open}
        icon="logistic"
        title={NLS.format("LOGISTICS_EDIT_TEXT", elemCode)}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns>
            <InputField
                type="toggle"
                name="isActive"
                label="GENERAL_IS_ACTIVE_FEM"
                value={data.isActive}
                error={errors.isActive}
                onChange={handleChange}
                withBorder
            />

            <InputField
                type="double"
                label="LOGISTICS_FEE"
                error={errors.fee}
                onChange={handleChange}
                isRequired
            >
                <InputItem
                    type="select"
                    name="feeType"
                    options="SELECT_FEE_TYPES"
                    value={data.feeType}
                    hideClear
                />
                <InputItem
                    type="number"
                    name="feeValue"
                    value={data.feeValue}
                    icon={data.feeType === "Fixed" ? "price" : ""}
                    postIcon={data.feeType === "Percent" ? "percent" : ""}
                    minValue={0}
                    step={0.01}
                />
            </InputField>

            <InputField
                type="double"
                error={errors.km}
                onChange={handleChange}
                isRequired
            >
                <InputItem
                    type="number"
                    name="minKM"
                    label="LOGISTICS_MIN_KM"
                    value={data.minKM}
                    onChange={handleChange}
                    minValue={0}
                    step={1}
                />
                <InputItem
                    type="number"
                    name="maxKM"
                    label="LOGISTICS_MAX_KM"
                    value={data.maxKM}
                    onChange={handleChange}
                    minValue={0}
                    step={1}
                />
            </InputField>

            <InputField
                type="number"
                name="position"
                label="GENERAL_POSITION"
                value={data.position}
                error={errors.position}
                onChange={handleChange}
            />
        </Columns>

        <InputField
            type="fields"
            name="timeTables"
            title="GENERAL_PERIOD_TITLE"
            addButton="GENERAL_ADD_PERIOD"
            value={data.timeTables}
            errors={errors}
            onChange={handleChange}
            columns="2"
            isRequired
        >
            <InputItem
                type="chooser"
                name="days"
                label="GENERAL_DAYS"
                options="DATE_DAY_NAMES"
                columns="2"
                isRequired
            />
            <InputItem
                type="time"
                name="from"
                label="GENERAL_FROM"
                isRequired
            />
            <InputItem
                type="time"
                name="to"
                label="GENERAL_TO"
                isRequired
            />
        </InputField>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
LogisticEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemCode : PropTypes.string,
};

export default LogisticEdit;
