import React                from "react";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";

// Components
import ProcessSection       from "../Utils/ProcessSection";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Container = Styled(ProcessSection)`
    justify-content: center;
    align-items: center;
`;

const Item = Styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
    gap: 8px;

    .icon {
        font-size: 32px;
    }
`;

const Code = Styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    gap: 8px;
`;



/**
 * The Process View Order
 * @returns {React.ReactElement}
 */
function ProcessOrder() {
    const { elem } = Store.useState("orderManage");


    // Variables
    const retirementIcon = elem.retirementCode?.toLowerCase() ?? "retirement";


    // Do the Render
    return <Container message="ORDERS_ORDER_INFO">
        <Item>
            <Icon icon="time" />
            <div>
                <b>{NLS.get("GENERAL_CREATED_AT_FEM")}</b><br />
                {elem.createdDate}
            </div>
        </Item>

        {elem.gatewayName && <Item>
            <Icon icon={elem.gatewayIcon} />
            <b>{elem.gatewayName}</b>
        </Item>}

        <Item>
            <Icon
                icon={retirementIcon}
                color={elem.retirementTypeColor}
            />
            <b>{elem.retirementTypeName}</b>
        </Item>

        {!!elem.logisticExternalID && <Code>
            <b>{NLS.get("ORDERS_LOGISTIC_EXTERNAL_ID")}</b>
            <i>{elem.logisticExternalID}</i>
        </Code>}
    </Container>;
}

export default ProcessOrder;
