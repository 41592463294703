import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Schedule Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ScheduleFilter(props) {
    const { open, filters, pharmacyID, forSubsidiary, onClose, onSubmit } = props;

    const { pharmacies, subsidiaries, subsidiariesPerPharmacy } = Store.useState("subsidiarySchedule");


    // The Initial Data
    const initialData = {
        exclude       : 0,
        pharmacyIDs   : [],
        subsidiaryIDs : [],
        days          : [],
        withSchedule  : "",
    };

    // The Filter State
    const {
        loading, data, handleChange, handleSubmit,
    } = useFilter("subsidiarySchedule", open, initialData, filters, onSubmit);

    // Returns a select of subsidiaries based on the pharmacies
    const pharmacyIDs     = pharmacyID ? [ pharmacyID ] : data.pharmacyIDs;
    const getSubsidiaries = Utils.useSelectList(loading, pharmacyIDs, subsidiaries, subsidiariesPerPharmacy);


    // Do the Render
    const showPharmacy = !forSubsidiary && !pharmacyID;

    return <FilterDialog
        open={open}
        title="SCHEDULES_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            isHidden={forSubsidiary}
            type="toggle"
            name="exclude"
            label="GENERAL_EXCLUDE_FILTER"
            value={data.exclude}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!showPharmacy}
            type="chooser"
            name="pharmacyIDs"
            label="PHARMACIES_NAME"
            options={pharmacies}
            value={data.pharmacyIDs}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="subsidiaryIDs"
            label="SUBSIDIARIES_NAME"
            options={getSubsidiaries()}
            value={data.subsidiaryIDs}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="days"
            label="GENERAL_DAYS"
            options="DATE_DAY_NAMES"
            value={data.days}
            onChange={handleChange}
        />
        <InputField
            type="select"
            name="withSchedule"
            label="SCHEDULES_HAS_SCHEDULE"
            options="GENERAL_SET_YES_NO"
            value={data.withSchedule}
            onChange={handleChange}
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ScheduleFilter.propTypes = {
    open          : PropTypes.bool.isRequired,
    onClose       : PropTypes.func.isRequired,
    onSubmit      : PropTypes.func.isRequired,
    filters       : PropTypes.object,
    pharmacyID    : PropTypes.number,
    forSubsidiary : PropTypes.bool,
};

export default ScheduleFilter;
