import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import ViewColumns          from "Components/Utils/View/ViewColumns";
import ViewItem             from "Components/Utils/View/ViewItem";



// Styles
const Container = Styled(ViewColumns)`
    flex-shrink: 0;
    box-sizing: border-box;
    height: 160px;
    overflow: auto;
`;



/**
 * The Order Metrics
 * @returns {React.ReactElement}
 */
function OrderMetrics() {
    const { metrics, prompts } = Store.useState("order");


    // Do the Render
    if (Utils.isEmpty(metrics) || !metrics.id) {
        return <React.Fragment />;
    }
    return <Container amount="1">
        <ViewItem
            message={prompts.PhoneRequest}
            value={metrics.phoneRequest}
        />
        <ViewItem
            message={prompts.PhoneSent}
            value={metrics.phoneSent}
        />
        <ViewItem
            message={prompts.CancelRequest}
            value={metrics.cancelRequest}
        />
        <ViewItem
            message={prompts.InPersonRequest}
            value={metrics.inPersonRequest}
        />
        <ViewItem
            message={prompts.DeliveryRequest}
            value={metrics.deliveryRequest}
        />
        <ViewItem
            message={prompts.NewOrderRequest}
            value={metrics.newOrderRequest}
        />
        <ViewItem
            message={prompts.ConversationTone}
            value={metrics.conversationTone}
        />
        <ViewItem
            message="ORDERS_AVG_RESPONSE_TIME"
            value={metrics.avgResponseText}
        />
    </Container>;
}

export default OrderMetrics;
